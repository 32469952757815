import React from "react";

import { Button } from "@blueprintjs/core";
import { Link } from "@reach/router";

import Layout from "../components/layout/auth";
import { Col } from "../components/containers";
import Spinner from "../components/Spinner";
import DeveloperAPI from "../services/DeveloperAPIClient";

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggingOut: true,
      logoutMessage: undefined
    };
    this.logout = this.logout.bind(this);
  }

  async logout() {
    let message;
    try {
      if (DeveloperAPI.isAuthenticated()) {
        await DeveloperAPI.logout();
      }
      message = "You have been logged out.";
    } catch (error) {
      if (error.networkError) {
        message = error.message;
      } else if (error.server) {
        message =
          "Server error. You have been logged out locally and will be able to login again.";
      } else if (error.client) {
        message = "Logout error. This will not impact your ability to login.";
      } else {
        message = `Logout error. ${error.message || "Unknown Error."}`;
      }
    }
    this.setState({ loggingOut: false, logoutMessage: message });
  }

  componentDidMount() {
    this.logout();
  }

  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    if (this.state.loggingOut === false && !this.logoutMessage) {
      // huh/
    }

    return (
      <Layout title="Logout">
        <div style={{ width: 300 }}>
          {this.state.loggingOut ? (
            <Spinner />
          ) : (
            <Col align={"center"}>
              <div>{this.state.logoutMessage}</div>
              <Link to="/login/" style={{ width: "100%", marginTop: 30 }}>
                <Button large fill>
                  Go To Login Page
                </Button>
              </Link>
            </Col>
          )}
        </div>
      </Layout>
    );
  }
}
export default Logout;
