import React from "react";
import Layout from "./base";
import logo from "../../images/bite_ai_logo.png";
import { Col } from "../containers";

export default ({ children, footer, ...rest }) => (
  <Layout
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#FAFAFA"
    }}
  >
    <Col style={{ alignItems: "center" }}>
      <img
        src={logo}
        alt={"Bite AI Logo"}
        width={200}
        style={{ marginBottom: 50 }}
      />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 6,
          padding: "50px 40px",
          boxShadow: "0px 0px 65px -26px rgba(0,0,0,0.37)",
          border: "1px solid #EAEAEA"
        }}
      >
        <Col style={{ padding: 0, alignContent: "center" }}>{children}</Col>
      </div>

      {footer}
    </Col>
  </Layout>
);
