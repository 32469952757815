import React from "react";

import "./containers.css";

export const Container = ({ children, className, ...rest }) => (
  <div className={`bai-container ${className}`} {...rest}>
    {children}
  </div>
);

export const Row = ({ children, className, ...rest }) => (
  <div className={`bai-row ${className}`} {...rest}>
    {children}
  </div>
);

export const Col = ({ children, className, ...rest }) => (
  <div className={`bai-col ${className}`} {...rest}>
    {children}
  </div>
);

export const Card = ({ children, style, ...rest }) => (
  <div
    style={{
      backgroundColor: "white",
      borderRadius: 6,
      padding: "50px 40px",
      boxShadow: "0px 0px 65px -26px rgba(0,0,0,0.37)",
      border: "1px solid #EAEAEA",
      ...style
    }}
    {...rest}
  >
    {children}
  </div>
);
